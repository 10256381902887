import * as React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout";


const Faq = () => {
  return (
    <Layout>
      <Container className="p-5">
        <h1 class="text-center">
          FAQ
        </h1>
        <h2 class="text-center">
          Twoje pytania
        </h2>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>Dlaczego warto wybrać Onvo?</h4>
        <p className="px-2">
          <ul>
            <li>Jesteśmy specjalistami - gwarantujemy zaangażowanie w każdy przypadek z osobna. Deklarujemy gotowość do pomocy i wspólne rozwiązywanie problemów w nieoczekiwanych okolicznościach.</li>
            <li>Całość dokumentacji prawnej jest weryfikowana przez kancelarię prawną specjalizującą się w obsłudze farm fotowoltaicznych, to zabezpieczenie skuteczności przed urzędami, organami prawnymi czy operatorami energetycznymi.</li>
            <li>Nasze zróżnicowane doświadczenie zawodowe, sprawia, że mamy kompetencje w zarządzaniu nieruchomościami, projektach budowlanych, poruszaniu się w świecie WZ i MPZP. Rozumiemy rachunek ekonomiczny właściciela działki dlatego zadbamy o to by umowa była korzystna finansowo.</li>
            <li>Jesteśmy zmęczeni poziomem usług w Polsce, trudnościami żeby cokolwiek załatwić w banku, na poczcie, u operatora sieci komórkowej. Dlatego najważniejszy jesteś Ty, nasz partner, z którym skutecznie przeprowadzimy proces dzierżawy.  Będziemy dumni jeśli nasze usługi z satysfakcją polecisz innym.</li>
            <li>Zależy nam na Twoim komforcie, dlatego wszędzie (gdzie to możliwe) samodzielnie będziemy występować i załatwiać wszelkie niezbędne formalności. </li>
            <li>Zawsze znajdziemy dla Was czas, nawet jeżeli nie będziemy w stanie wspólnie zrealizować farmy – to chętnie odpowiemy na wszelkie pytania i wytłumaczymy dlaczego nie jest to możliwe.</li>
          </ul>
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>Ile można na tym zarobić? Ile możesz zarobić posiadając farmę fotowoltaiczną na Twojej działce?</h4>
        <p className="px-2">
          <ul>
            <li>W zależności od specyfiki działki możesz liczyć na przychód w granicach 10.000-15.000 zł/ha/rok. Czyli na 2-hektarowej działce w ciągu 25 lat dzierżawy można uzyskać 600.000pln – nie uwzględniając waloryzacji stawek o stopę inflacji. Innymi słowy można spokojnie kupić dwa niewielkie mieszkania w Krakowie i spłacać je z przychodów z działki. A po 25 latach dalej masz swoją działkę do dyspozycji i dwa mieszkania, albo kapitalne wspomnienia z 25 wakacji opłaconych przez słońce.</li>
            <li>Onvo zawsze gwarantuje waloryzację stawki rocznej o stopę inflacji. W wyjątkowo atrakcyjnych lokalizacjach jesteśmy skłonni negocjować procent od przychodów zamiast stałej stawki rocznej.</li>
          </ul>
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>Ile to kosztuje czasu i pieniędzy?</h4>
        <p className="px-2">
          <ul>
            <li>Jeżeli chodzi o pieniądze to odpowiedź jest krótka 0,00 pln. Całość kosztów bierzemy na siebie, właściciel gruntu nie będzie musiał wyłożyć ani jednej złotówki. </li>
            <li>Chcielibyśmy powiedzieć, że nie będzie potrzebne zaangażowanie czasu. Niestety będziemy potrzebowali kilku godzin, na wspólną analizę umowy dzierżawy, na omówienie wszystkich wątpliwości dotyczących inwestycji,  a także na wizytę u notariusza w celu podpisania umowy. To wszystko.</li>
            <li>Po podpisaniu umowy zaczynamy przygotowywać projekt i kompletować niezbędne formalności - co trwa średnio 12 miesięcy. Niemniej do czasu rozpoczęcia budowy możesz swobodnie korzystać z dzierżawionej nam nieruchomości!</li>
          </ul>
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>A jeżeli mimo starań nie uda się skompletować dokumentacji potrzebnej do wykonania inwestycji?</h4>
        <p className="px-2">
          <ul>
            <li>W takiej sytuacji pozostanie oczywiście niedosyt jednak z perspektywy właściciela działki nie ma żadnych konsekwencji prawnych i finansowych – jest za to pewność, że akurat teraz nie ma możliwości realizacji projektu. Pozostaje także kontakt z nami w przypadku gdyby okoliczności formalno-prawne uległy zmianie – jesteśmy zawsze do dyspozycji, choćby tylko w celu niezobowiązujących  konsultacji.</li>
          </ul>
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>Kto zajmuje się utylizacją paneli po zakończeniu dzierżawy?</h4>
        <p className="px-2">
          <ul>
            <li>Onvo w każdej umowie dzierżawy zobowiązuje się wziąć koszty utylizacji na siebie i przywrócić działkę do stanu sprzed inwestycji. </li>
            <li>Na wielu forach można się spotkać z informacjami, że dzierżawcy utylizacja się nie opłaci i po prostu Spółka zbankrutuje – w naszej opinii to bzdura. Po pierwsze bardzo prawdopodobny jest scenariusz, że w trakcie 25letniej dzierżawy zdecydujemy się na wymianę paneli, żeby podnieść ich wydajność i będziemy zainteresowani przedłużeniem dzierżawy. Po drugie mimo, że obecnie nie ma możliwości odzyskiwania komponentów, z których produkowane są panele, to postęp technologiczny to umożliwi wcześniej niż za 25 lat. Po trzecie poziom świadczonych usług to dla nas priorytet i jeżeli na coś się umówimy to dla nas jest to wyryte w kamieniu.</li>
            <li>Zgodnie z podpisaną umową dzierżawy utylizacją Paneli zajmie się ONVO. Zapisy umowy są także spójne z polskim prawem (art. 705 KC) które po okresie dzierżawy zobowiązuje dzierżawcę zwrócić przedmiot dzierżawy w takim stanie, w jakim powinien się znajdować stosownie do przepisów o wykonywaniu dzierżawy.</li>
          </ul>
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>Jakie czynniki mają wpływ na wynagrodzenie za dzierżawę?</h4>
        <p className="px-2">
          <ul>
            <li>Kluczowa jest wielkość, nasłonecznienie i kształt działki. Ważne jest również nachylenie gruntu i zacienienie działki. Na pewno w negocjacjach ceny pomaga odległość od sieci średniego napięcia – im bliżej tym lepiej, brak trudności w dostępie do sieci również będzie pomocą. Praktycznie niezbędny jest dostęp do utwardzonej drogi.</li>
          </ul>
        </p>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>Inne pytania?</h4>
        <p className="px-2">
          <ul>
            <li><a href="mailto:biuro@onvo.pl">Napisz do nas</a>, wykorzystaj <a href="/kontakt">formularz kontaktowy</a>, złap nas na <a href="https://www.facebook.com/OnvoBiuro/">facebooku</a> – chętnie rozwiejemy wszelkie wątpliwości.</li>
          </ul>
        </p>
      </Container>
    </Layout>
  )
}

export default Faq
